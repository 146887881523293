<!--
 * @Author: lbh
 * @Date: 2022-06-22 10:48:38
 * @LastEditors: lbh
 * @LastEditTime: 2023-02-04 11:37:33
 * @Description: file content
-->
<template>
  <div class="edit-image-box">
    <div class="cell-b">
      <h3>圖片屬性</h3>
      <selfCell title="選擇圖片">
        <selfUpload
          v-model="configs.src"
          type="default"
          @change="setValue('src')"
        />
      </selfCell>
      <selfCell title="選擇移動端圖片">
        <selfUpload
          v-model="configs.phoneSrc"
          type="default"
          @change="setValue('phoneSrc')"
        />
      </selfCell>
      <selfCell title="請輸入圖片描述">
        <el-input
          v-model="configs.alt"
          type="textarea"
          @change="setValue('alt')"
        ></el-input>
      </selfCell>
      <selfCell title="跳轉頁面">
        <selfPagePicker v-model="configs.go" />
      </selfCell>
    </div>
  </div>
</template>

<script>
export default {
  name: "edit-image",
  props: {
    configs: {
      default () {
        return {
          src: "",
          alt: '55555',
          go: ""
        }
      }
    }
  },
  methods: {
    setValue (key) {
      console.log('key:', key, 'value', this.configs[key]);
      this.$emit('setValue', { key: key, value: this.configs[key] })
    }
  }
}
</script>

<style lang="less" scoped>
</style>